import React from 'react'
import {graphql} from 'gatsby'
import SEO from '../components/seo'

export const query = graphql`
  query NotFoundPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      favicon {
        asset {
          url
        }
      }
    }
    logo: sanityLogo {
      logo {
        asset {
          url
        }
      }
      alt
    }
  }
`

const NotFoundPage = ({data}) => (
  <div>
    <SEO title='404: Not found' logo={data.logo} favicon={data.site.favicon} />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
)

export default NotFoundPage
